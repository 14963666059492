
export const ImageBanner = {
   Slider1: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732818443/assets/banner/b4uiweepgzwmhmwfkhr8",
   Slider2: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732818449/assets/banner/sevoasqc3zqjzsl0qivg",
   Slider3: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732818454/assets/banner/bvg1mk7v2emq7se5xvpq",
}

export const ImageChooseUs = {
   ChooseUs1: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732819774/assets/WhyChoseUs/mgnk3mdeju8en9clg19p",
   ChooseUs2: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732819793/assets/WhyChoseUs/emih0ufgyfwqa21hgfu7",
   ChooseUs3: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732819806/assets/WhyChoseUs/bzg5h2vjv3rhxjrqsv57",
   ChooseUs4: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732819802/assets/WhyChoseUs/i1y6gvvbsely8xxaoiww",
}

export const ImagePlatform = {
   PlatformOSS: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732818461/assets/platform/gthuheapeweqnwhav4hg",
   PlatformKemendag: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732818459/assets/platform/ymnjzxefkyobavipm7rq",
   PlatformKemenham: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732818457/assets/platform/pkzfm1x7jadf0yhqiqj6",
   PlatformPajak: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_webp,fl_awebp,q_auto/v1732818457/assets/platform/kmgvzmrmye5aaw2spvn9"
}

export const ImageAboutUs = {
   AboutUs1: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_auto,q_auto/v1/assets/about-us/rmtwymas6rhiroodwobc",
   AboutUs2: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_auto,q_auto/v1/assets/about-us/poiw6meximxscsywlcky",
   AboutUs3: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_auto,q_auto/v1/assets/about-us/uo6iapdyoog3ysiohuk7",
   AboutUs4: "https://res.cloudinary.com/dyfgtumyj/image/upload/f_auto,q_auto/v1/assets/about-us/fgoz8zhe1lw0lb7ghjze",
}
import React from "react";

import { ImagePlatform } from "../../../assets/images/image";



const itemsPlatform = [
   { image: ImagePlatform.PlatformOSS, alt: 'logo-oss' },
   { image: ImagePlatform.PlatformKemendag, alt: 'logo-Kemdagri' },
   { image: ImagePlatform.PlatformKemenham, alt: 'logo-kemenkum-ham' },
   { image: ImagePlatform.PlatformPajak, alt: 'logo-Djp' },
];
const RegisteredPlatform = () => {
   return (
      <div className="section-full content-inner exp-services">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">
                  <div className="section-head text-black text-center">
                     <h2 className="box-title m-tb0">WE ARE REGISTERED ON<span className="bg-primary"></span></h2>
                  </div>
                  <div className="row serv">
                     {itemsPlatform.map((data, index) => (
                        <div className="col-lg-3 col-md-6 col-sm-6">
                           <div className="item-box m-tb30">
                              <div className="item-img">
                                 <img src={data.image} alt={data.alt} />
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default RegisteredPlatform;
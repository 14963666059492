import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { ImageBanner } from '../../assets/images/image';

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className="owl-next la la-angle-right" onClick={onClick} />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className=" owl-prev la la-angle-left" onClick={onClick} style={{ zIndex: 1 }} />
		</div>
	);
}

class HomeOwlSlider extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}


	render() {
		var settings = {
			arrows: true,
			dots: false,
			slidesToShow: 1,
			infinite: true,
			autoplay: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 1,

					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,

					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,

					}
				}
			]
		};
		return (

			<Slider className="owl-slider owl-carousel owl-theme owl-none" {...settings}>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={ImageBanner.Slider1} className="w-100" alt="" /></div>
					<div className="slide-content overlay-primary">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">We would great ideas into<br /> prodigious and innovative products<br /></h2>
								<p>A forward thinking representation for a research production and the ability to do it dynamically with broad stability and drive your business up to a higher level.</p>
								{/* <Link to={"#"} className="site-button m-r10 white button-lg">Get Started</Link>
								<Link to={"#"} className="site-button outline outline-2 button-lg">How It Work</Link> */}
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={ImageBanner.Slider2} className="w-100" alt="" /></div>
					<div className="slide-content overlay-primary">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">Indonesian Supply Chain</h2>
								<p>Indonesia, the largest archipelago in the world, is blessed with abundant natural resources. From tropical rainforests that store biodiversity, to fertile agricultural land that supports food production. Mineral mines, marine resources and other geological wealth are an integral part of Indonesia's natural potential.</p>
								{/* <Link to={"#"} className="site-button m-r10 white button-lg">Get Started</Link>
								<Link to={"#"} className="site-button outline outline-2 button-lg">How It Work</Link> */}
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={ImageBanner.Slider3} className="w-100" alt="" /></div>
					<div className="slide-content overlay-primary">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">Seamless supply chains that increase your business potential</h2>
								<p>A forward thinking representation for a research production and the ability to do it dynamically with broad stability and drive your business up to a higher level.</p>
								{/* <Link to={"#"} className="site-button m-r10 white button-lg">Get Started</Link>
								<Link to={"#"} className="site-button outline outline-2 button-lg">How It Work</Link> */}
							</div>
						</div>
					</div>
				</div>
			</Slider>
		)

	}

}

export default HomeOwlSlider;